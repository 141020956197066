import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
// STYLES
import { ThemeContext } from 'layout/ThemeProvider'
import Container from 'react-bootstrap/Container'
import Header from 'layout/Header'
import Footer from 'layout/Footer'
import 'styles/main.scss'
// HOOKS
import { useSiteMetadata } from 'hooks/useSiteMetadata'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

const Layout = ({ children, header, noFooter = false, stickyFooter = '' }) => {
  const { themeMode, invertedTheme } = useContext(ThemeContext)
  const [scrollState, setScrollState] = useState('top')

  useScrollPosition(({prevPos, currPos}) => {
    const navBarHeight = -75       // Change state when scrolled past this y position
    if (currPos.y <= navBarHeight)
      setScrollState('top')
    else
      setScrollState(currPos.y)
  })

  const { title: metaTitle } = useSiteMetadata()
  const siteTitle = header ? header.siteTitle : metaTitle
  // console.log({themeMode})
  return (
  <>
  <Container id='layout' className={`px-0 bg-${themeMode}`} fluid>
    { header &&
    <Header
      header={header}
      siteTitle={siteTitle}
      scrollState={scrollState}
    />
    }
    {children}
    {/* NON-STICKY Footer */}
    { !noFooter && 
      <Footer siteTitle={siteTitle}/>
    }
  </Container>
  {/* STICKY FOOTER */}
  {stickyFooter && 
  <Footer theme={{themeMode, invertedTheme}} sticky={true} >
    {stickyFooter}
  </Footer>
  }
  </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
export default Layout

