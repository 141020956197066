import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'

import { ThemeContext } from 'layout/ThemeProvider'

const ContentContainer = ({ id, cntrType='default', type='default', inverted, children, styles }) => {
  let { themeMode, invertedTheme } = useContext(ThemeContext)
  // inverted forces style inversion on component
  if (inverted) [themeMode, invertedTheme] = [invertedTheme, themeMode]

  const containerType = {
    default: 'p-0',
    fullHeight: 'p-0 vh-100'
  }
  const rowType = {
    default: `justify-content-center`,
    centered: `d-flex justify-content-center container-fluid vh-100 bg-${themeMode} text-${invertedTheme}`,
    simpleSignup: `d-flex justify-content-center container-fluid bg-${themeMode} text-${invertedTheme}`,
  }
  const colType = {
    default: 'col-12 p-0 m-0',
    centered: 'col-12 p-0 my-auto',
    body: `col-md-8 col-11 py-2 bg-${themeMode} text-${invertedTheme}`,
    // sidebar:      "col-md-2 px-2 py-2 my-2",
    secondary: `col-12 col-md-8 py-4 text-center bg-${themeMode} text-${invertedTheme}`,
    section: `col-12 px-4 py-5 text-center bg-${themeMode} text-${invertedTheme}`,
    sectionPlain: `col-12 px-4 py-5 text-center text-${invertedTheme}`,
    sectionEndcap: `col-12 p-0 bg-${invertedTheme}`,
    standout: `col-12 py-4 my-4 text-center bg-${themeMode} text-${invertedTheme}`,
    title: `col-12 px-2 py-2 text-center bg-${themeMode} text-${invertedTheme}`,
    simpleSignup: `col-12 col-lg-10 text-center bg-${themeMode} text-${invertedTheme}`,
  }
  // console.log({
  //   cntrType,
  //   type,
  //   ct: containerType[cntrType],
  //   rt: rowType[type],
  //   ict: colType[type]
  // })
  return (
  <Container id={id} className={containerType[cntrType]} fluid>
    <Row className={rowType[type]} noGutters>
      <div className={colType[type]} style={styles} >{children}</div>
    </Row>
  </Container>
  )
}

ContentContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

ContentContainer.defaultProps = {
  type: 'default',
  inverted: false,
  id: 'content-container',
}

export default ContentContainer
