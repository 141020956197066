// import React, { useContext } from 'react'
import React from 'react'
import { Link } from 'gatsby'
import Navbar from 'react-bootstrap/Navbar'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Footer = ({ siteTitle, theme, children, sticky = false }) => {
  const cn = sticky ? `px-0 py-0 bg-${theme.themeMode} text-${theme.invertedTheme}` : ``
  return (
    <Navbar className={cn} as='footer' fixed={sticky ? `bottom` : ``} >
    { !sticky ?
      <Row className='align-items-center p-3 no-gutters container-fluid'>
        <Col className='text-center text-muted small'>
          <Link to='/info'>
            {siteTitle} &copy; {new Date().getFullYear()} VE&Gamma;I&Gamma;EV
          </Link>
          <br />
          <Link to='/info'>Made with love in Los Angeles</Link>
          <br />
        </Col>
      </Row> : children
      }
    </Navbar>
  )
}

export default Footer
